<template>
  <v-main class="login">
    <div class="pt-12 text-center">
      ログイン処理中
    </div>
    <v-overlay :value="loading">
      <v-progress-circular :size="80" :width="2" class="maincolor" indeterminate></v-progress-circular>
    </v-overlay>
  </v-main>
</template>
<script>

export default {
  data() {
    return {
      init: [],
      loading: true,
    }
  },
  async created() {
    //createdで、localStorageから全ての保存情報を取得しinitに格納
    this.init = this.storageGet('*')

    try {
      const yamaya_id = this.$route.query.yamaya_id
      const login_action = this.$route.query.login_action

      console.log('yamaya_id : ' + yamaya_id)
      console.log('login_action : ' + login_action)

      this.storageSave(JSON.stringify({
        'device_uid': yamaya_id,
      }))

      // アクセストークン発行API
      const token_req = {
        'type': 'App',
        'auth_code': this.init.auth_code,
        'os': this.init.os,
        'yamaya_id': yamaya_id,
      }
      const token_res = await this.apiCallCustomerPF('/access_token', token_req)
      this.storageSave(JSON.stringify({
        'access_token': token_res.access_token,
      }))

      // ユーザ取得
      const user_req = {
        'yamaya_id': yamaya_id,
      }
      const user_res = await this.apiCallCustomerPF('/user/get', user_req)

      this.storageSave(JSON.stringify({
        'user_id': user_res.user.id,
        'user_name': user_res.user.name,
        'nickname': user_res.user.nickname, // nativeにはニックネームを返すために保存しておく
      }))

      if (login_action === 'new-account') {
        // 過去に配信されたプッシュ通知（アプリ内通知のみ）を新規会員向けにレコード作成
        const notification_req = {
          'user_id': user_res.user.id,
          'device_uid': yamaya_id,
          'os': this.init.os,
        }
        await this.apiCall('/user/create_pushed_notifications', notification_req, 'post')

        // 本店をフォローする
        const favorite_req = {
          'shop_id': this.init.sid,
          'flg': 1,
          'device_uid': yamaya_id,
          'os': this.init.os,
        }
        await this.apiCall('/user/favorite', favorite_req, 'post')
      }

      const params = {
        '_vue_param': true,
        'login_action': 'show-top-page',
        'yamaya_id': yamaya_id,
        'user_id': user_res.user.id,
        'user_name': user_res.user.nickname, // nativeにはニックネームを返す
        'Access-Token': token_res.access_token,
      }
      this.nativePost(params)
      //
      // // バッジをinitで再取得
      // await this.initAfterNewAccountOrLogin()
      //
      // // お気に入り店舗が登録済みかをチェックする（本店以外）
      // const shop_list_req = {
      //   params: {
      //     'sid': this.init.sid,
      //     'device_uid': yamaya_id,
      //     'device_id': this.init.device_id,
      //     'os': this.init.os,
      //     'key': this.init.key,
      //     'location': 0,
      //     'feature_id': 'top', // 必須パラメータだった。
      //   }
      // }
      // const shop_list_res = await this.apiCall('/user/shops', shop_list_req)
      //
      // // お気に入り登録を促すダイアログを表示するか
      // if (shop_list_res.recommend_shop_list.length === 0) {
      //   await this.showFavoritePromptDialog()
      // }
    } catch (e) {
      console.log(e);
      this.callDialog('通信エラー', 'ネットワークの状態が不安定です。再度お試しください', 3);
    } finally {
      this.loading = false;
    }
  },
}
</script>

<style scoped>
.login {
  height: 100vh;
  margin: auto;
}
</style>
